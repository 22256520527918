<template>
  <div class="shop_car">
    <!--list-->
    <div v-if="list.length===0" class="zj" @click="goShop()">
      <div class="h1_title">购物车竟然是空的</div>
      <div class="h2_title">在忙，也要记得买点什么犒赏自己～</div>
      <div class="zj_go">去逛逛</div>
    </div>
    <div v-for="(item,index) in list" v-else :key="index" class="shop_car_bg">
      <!--1 top 标题 -->
      <div>
        <!--标题-->
        <div class="shop_car_top">
          <div class="checkbox" :class="item.selected==0? '' : 'on'" @click="btnCheckbox(item,0)" />
          <img :src="item.imgIcon" alt="">
          <span>{{ item.name }}</span>
        </div>
        <!--渠道判断 0 普通 1 9块9 2满减 3 会场打折 4白拿-->
        <div v-for="(son,sonIndex) in item.activityData" :key="sonIndex" class="shop_car_bot">
          <!--jd-->
          <div v-if="son.isSpecialEvent==0 && item.channelId!=0 && item.channelId!=12 && sonIndex<1">
            <span v-if="item.allMoney-freightRule>0" class="shop_car_l">已免运费</span>
            <span v-else>
              <span class="shop_car_l">还差{{ (freightRule - item.allMoney).toFixed(2) }}元免运费</span>
              <span class="shop_car_r">
                <span v-if="son.isSpecialEvent==0 && item.channelId!=7 && item.channelId!=8" @click="goDetails(item,0)">去凑单></span>
                <!--当当书城 暂时h5 没有页面-->
                <span v-else-if="item.channelId!=7" @click="goDetails(item,7)" />
                <!--悦淘国际-->
                <span v-else-if="item.channelId!=8" @click="goDetails(item,5)">去凑单></span>
              </span>
            </span>
          </div>
          <!--9块9 -->
          <div v-if="son.isSpecialEvent==1">
            <span class="shop_car_l"> 9块9</span>
            <span class="shop_car_r" @click="goDetails(item,1)">去凑单></span>
          </div>
          <!--满减 -->
          <!--          <div v-if="son.isSpecialEvent==2">-->
          <!--            <span class="shop_car_l"> 满减</span>-->
          <!--            <span class="shop_car_r" @click="goDetails(item,2)">去凑单></span>-->
          <!--          </div>-->
          <!--          &lt;!&ndash;会场打折 &ndash;&gt;-->
          <!--          <div v-if="son.isSpecialEvent==3">-->
          <!--            <span class="shop_car_l"> 会场打折</span>-->
          <!--            <span class="shop_car_r" @click="goDetails(item,3)">去凑单></span>-->
          <!--          </div>-->
          <!--白拿商品-->
          <!--          <div v-if="son.isSpecialEvent==4">-->
          <!--            {{son.selected}}-->
          <!--            <span class="shop_car_l"> 还差(1)件商品可免运费</span>-->
          <!--            <span class="shop_car_r" @click="btnDetails(item,4)">去凑单></span>-->
          <!--          </div>-->

        </div>
      </div>
      <!--2 右边滑 中间部分-->
      <div v-for="(son,sonIndex) in item.activityData" :key="sonIndex">
        <div>
          <div class="list-box">
            <!-- 循环内容 -->
            <div v-for="(sonItem,ind) in son.productList" :key="ind" class="list-item" data-type="0">
              <div class="list_all" @touchstart.capture="touchStart" @touchend.capture="touchEnd" @click="skip">
                <!--checkbox-->
                <div class="checkbox" :class="sonItem.selected==0? '' : 'on'" @click="btnCheckbox(sonItem,1)" />
                <!--img-->
                <div class="list_all_img" @click="goLink(sonItem)">
                  <img :src="sonItem.goodCover" alt="">
                </div>
                <!--内容-->
                <div class="list_all_span">
                  <h1>
                    {{ sonItem.goodName }}
                  </h1>
                  <h2><span v-if="sonItem.goodSpec">{{ sonItem.goodSpec }}</span></h2>
                  <h3>官方价¥{{ sonItem.nowPrice }}</h3>
                  <h4>
                    <span>¥</span>
                    <h5>{{ sonItem.newVipPric }}</h5>
                  </h4>
                </div>
                <div style="clear: both" />
                <!--计算-->
                <div class="list_all_nub_btn">
                  <div @click="reduceNumber(0,sonItem)">-</div>
                  <div>{{ sonItem.goodNum }}</div>
                  <div @click="reduceNumber(1,sonItem)">+</div>
                </div>
              </div>
              <div class="delete" :data-index="index" @click="deleteItem(sonItem)">删除</div>
            </div>
          </div>
        </div>
      </div>
      <!--3 bottom 优惠结算-->
      <div class="shop_car_bottom">
        <span class="shop_car_yh">活动优惠</span>
        <span>共计优惠</span>
        <span class="shop_car_nub">{{ (item.tolPric).toFixed(2) }}</span>
        <span>元</span>
      </div>
      <!--总价格-->
      <div class="allPrice">
        <div class="checkbox" :class="item.selected==0? '' : 'on'" @click="btnCheckbox(item,2)" />
        <div class="all_price_all">全选</div>
        <div class="all_price_nub">
          <h5>合计: <span>¥{{ (isPrice).toFixed(2) }}</span></h5>
          <h6>活动已经优惠{{ (discountPrice).toFixed(2) }}</h6>
        </div>
        <div v-if="discountPrice!=0" class="all_price_btn" @click="onSubmit()"> 结算({{ totalCount }})</div>
        <div v-else class="all_price_btn bf"> 结算</div>
      </div>
    </div>

    <!--失效 list-->
    <div>
      <div v-show="LoseEfficacyCount!=0">
        <div class="lost_box">
          <div class="lost_box_l">失效宝贝（{{ LoseEfficacyCount }}）件</div>
          <div class="lost_box_r" @click="deleteItem(1)">清空失效宝贝</div>
        </div>
        <div class="list-box">
          <!-- 循环内容 -->
          <div v-for="(item,ind) in LoseList" :key="ind" class="list-item" data-type="0">
            <div class="list_all" @touchstart.capture="touchStart" @touchend.capture="touchEnd" @click="skip">
              <!--checkbox-->
              <div class="checkbox_no">
                <span>失效</span>
              </div>
              <!--img-->
              <div class="list_all_img">
                <img :src="item.goodCover" alt="">
              </div>
              <!--内容-->
              <div class="list_all_span">
                <h6 class="h6">{{ item.goodName }}</h6>
                <h1 class="h1">该商品已下架</h1>
              </div>
              <div style="clear: both" />
            </div>
            <div class="delete" :data-index="ind" @click="deleteItem(item)">删除</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable eqeqeq */

import { shopDeletes, getLoseGoods, newList, handleGoodsCount, changeChoseStatus } from '@/services/goods.js'
import { Toast } from 'vant'

export default {
  name: 'GoShopCar',
  data() {
    return {
      startX: 0, // 滑块
      endX: 0, // 滑块
      list: '', // list
      LoseList: '', // 失效 list
      LoseEfficacyCount: '', // 失效数量
      isCheckbox: false, // checkbox
      isPrice: 0, //  总价格
      discountPrice: 0, // 活动优惠 总价格
      freightRule: '', // 京东 运费价格
      totalCount: ''
    }
  },
  mounted() {
    this.getData()
    this.getLoseGoodsData()
  },
  methods: {
    // list
    getData: function() {
      const data = {
        uid: window.localStorage.getItem('uid')
      }
      newList(data).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data.groupProductList || []
          this.freightRule = res.data.freightRule
          // this.gitPrice() // 默认调用方法
          this.shopCarList = res.data || []
          if (this.list) {
            this.gitPrice() // 默认调用方法
          }
        }
      })
    },
    // 点击减
    // index 0,1   0 为减  1 为加
    // item 值
    reduceNumber: function(index, item) {
      if (index == 0) {
        if (item.goodNum == 1) {
          item.goodNum = 1
          Toast('商品数量不能为零')
          return
        } else {
          item.goodNum--
          this.gitHandleGoodsCount(1, item)
        }
      } else {
        item.goodNum++
        this.gitHandleGoodsCount(0, item)
      }
      this.gitPrice()
    },
    // 点击计算 加/减
    gitHandleGoodsCount: function(index, item) {
      const data = {
        cartId: item.cartId, // 购物车 商品 id
        type: index, // 0：增加 1：减少
        uid: window.localStorage.getItem('uid') || '', // uid
        channelId: 0 // 渠道id  现版本传0
      }
      handleGoodsCount(data).then(res => {
        if (Number(res.status) === 200) {
          this.getData()
        }
      })
    },

    // 点击全选
    // indexType 0 ,1, 2
    // 0 为 点击标题 全选
    // 1 为 点击部分 单选
    // 2 为 点击下边 全选
    btnCheckbox: function(item, indexType) {
      var data = {}
      if (indexType == 0) {
        data = {
          type: item.selected ? 0 : 1, // 选中状态1选中0不选中
          groupSelect: 'channel',
          channelId: item.channelId
        }
      } else if (indexType == 1) {
        data = {
          cartId: item.cartId,
          type: item.selected ? 0 : 1,
          groupSelect: 'single'
        }
      } else {
        data = {
          type: item.selected ? 0 : 1,
          groupSelect: 'all'
        }
      }
      data.uid = window.localStorage.getItem('uid') || ''
      changeChoseStatus(data).then(res => {
        if (Number(res.code) === 200) {
          this.getData()
        }
      })
    },
    // 计算 优惠
    getTolPric(itme) {
      let tol = 0
      if (itme.selected === 1) {
        tol = (itme.nowPrice - itme.newVipPric) * itme.goodNum
      }
      return tol
    },
    // 价格计算 方法
    gitPrice: function() {
      this.isPrice = 0
      this.discountPrice = 0
      this.totalCount = 0
      this.fmoney = 0
      this.list.forEach((item, index) => {
        item.tolPric = 0 // 部分优惠价格
        item.activityData.forEach((son, index) => {
          son.productList.forEach((itemList, index) => {
            // 1 计算 价格
            itemList.newVipPric = itemList.skPrice || itemList.vipPric // 判断 skPrice 字段 跟 vipPric
            if (itemList.selected == 1) {
              this.isPrice += (itemList.goodNum * itemList.newVipPric)
            }
            // 2 计算 部分优惠
            item.tolPric += this.getTolPric(itemList)
            // 3 计算 总 优惠
            if (itemList.selected == 1) {
              this.discountPrice += (itemList.nowPrice - itemList.newVipPric) * itemList.goodNum
            }
            // 结算数
            if (itemList.selected == 1) {
              this.totalCount += itemList.selected
              this.$store.dispatch('getCartCount')
              // this.$store.commit("gitCartCount" , this.totalCount)
            }
          })
        })
      })
    },
    goShop() {
      this.$router.push({ path: '/' })
    },

    // 失效 list
    getLoseGoodsData: function() {
      const data = {
        uid: window.localStorage.getItem('uid') || ''

      }

      getLoseGoods(data).then(res => {
        if (Number(res.code) === 200) {
          this.LoseList = res.data
          this.LoseEfficacyCount = res.LoseEfficacyCount
        }
      })
    },
    // 删除 list
    // item 为1 的时候 删除全部
    deleteItem: function(item) {
      this.restSlide()
      var arr = []
      var data = {}
      this.LoseList.forEach((sonItem, index) => {
        arr.push(sonItem.cartId)
      })
      if (item == 1) {
        data = {
          cartIdString: arr.toString()
        }
      } else {
        data = {
          cartIdString: item.cartId
        }
      }
      data.uid = window.localStorage.getItem('uid') || ''
      shopDeletes(data).then(res => {
        if (Number(res.code) === 200) {
          this.getData()
          this.getLoseGoodsData()
        }
      })
    },

    // 点击 去详情
    // inde  0 jd    4 白拿
    goDetails(item, index) {
      if (item.redirectType === 39) {
        this.$router.push({ path: '/acthds', query: { id: item.redirectParams.id }})
      } else if (item.redirectType === 43) {
        this.$router.push({ path: '/koala' })
      } else if (index == 4) {
        this.$router.push({ path: '/acthds', query: { id: item.activityData[0].activityId }})
      } else if (index == 5) {
        this.$router.push({ path: '/yueTaoInternational' })
      } else if (index == 7) {
        console.log('此处无代码')
      }
    },
    // 白拿
    btnDetails(item) {
      this.$router.push({ path: '/acthds', query: { id: item.activityData[0].activityId }})
    },
    // 点击 img 跳转
    goLink(item) {
      const obj = {
        product_type: 1, // 暂时写1
        goodId: item.goodId,
        skuId: item.product_sku_id
      }
      this.$store.dispatch('godetail', obj)
    },
    // 提交
    onSubmit() {
      let mk = []
      this.list.forEach((item, index) => {
        item.tolPric = 0 // 部分优惠价格
        item.activityData.forEach((son, index) => {
          son.productList.forEach((itemList, index) => {
            if (itemList.selected == 1) {
              mk.push(itemList.cartId)
            }
          })
        })
      })
      mk = mk.join(',')
      this.$router.push({
        path: '/placeCaroder',
        query: {
          cartId: mk,
          yhmoney: this.discountPrice.toFixed(2) // 暂时为0
        }
      })
    },

    // --------------------------------------右滑 start ----------------------------------------------
    skip() {
      if (this.checkSlide()) {
        this.restSlide()
      }
    },
    // 滑动开始
    touchStart(e) {
      // 记录初始位置
      this.startX = e.touches[0].clientX
    },
    // 滑动结束
    touchEnd(e) {
      // 当前滑动的父级元素
      const parentElement = e.currentTarget.parentElement
      // 记录结束位置
      this.endX = e.changedTouches[0].clientX
      // 左滑
      if (parentElement.dataset.type == 0 && this.startX - this.endX > 50) {
        this.restSlide()
        parentElement.dataset.type = 1
      }
      // 右滑
      if (parentElement.dataset.type == 1 && this.startX - this.endX < -50) {
        this.restSlide()
        parentElement.dataset.type = 0
      }
      this.startX = 0
      this.endX = 0
    },
    // 判断当前是否有滑块处于滑动状态
    checkSlide() {
      const listItems = document.querySelectorAll('.list-item')
      for (let i = 0; i < listItems.length; i++) {
        if (listItems[i].dataset.type == 1) {
          return true
        }
      }
      return false
    },
    // 复位滑动状态
    restSlide() {
      const listItems = document.querySelectorAll('.list-item')
      // 复位
      for (let i = 0; i < listItems.length; i++) {
        listItems[i].dataset.type = 0
      }
    }
    // ------------------------------------ 右滑 end ------------------------------------------------
  }
}
</script>
<style scoped lang="less">
.shop_car {
  width: 100%;
  margin-bottom: 90px;
  overflow: hidden;
  background: #f6f6f6;

  .zj {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .h1_title {
    color: #888;
    font-size: 16px;
  }

  .h2_title {
    color: #888;
    margin-top: 20px;
    font-size: 12px;
  }

  .zj_go {
    width: 70px;
    border: 1px solid red;
    padding: 5px 12px;
    border-radius: 17px;
    color: red;
    margin: 30px auto;
  }

  .allPrice {
    width: 100%;
    height: 60px;
    padding: 12px 0;
    background: #fff;
    position: fixed;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    bottom: 0;
    text-align: left;
    z-index: 9;

    .checkbox {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, .4);
      margin-left: 12px;
      float: left;
      margin-top: 6px;
    }

    .checkbox.on {
      width: 24px;
      height: 24px;
      background: url("../../assets/images/choose.png") no-repeat;
      background-size: 100% 100%;
      border: none;
    }

    .all_price_all {
      float: left;
      margin: 11px 15px 11px 12px;
    }

    .all_price_nub {
      float: left;

      h5 {
        margin-top: 0px;
        font-size: 12px;
        margin-bottom: 0px;

        span {
          font-size: 16px;
          color: red;
        }
      }

      h6 {
        margin-top: 6px;
        font-size: 10px;
        color: red;
      }
    }

    .all_price_btn {
      float: right;
      width: 92px;
      height: 34px;
      background: red;
      margin-right: 12px;
      border-radius: 24px;
      text-align: center;
      line-height: 34px;
      color: #fff;
    }

    .all_price_btn.bf {
      color: #fff;
      background: #de6060;
    }
  }

  .shop_car_bg {
    background: #fff;
    margin-bottom: 12px;
  }

  .list-item {
    position: relative;
    //height: 110px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, .04);
  }

  .list-item[data-type="0"] {
    transform: translate3d(0, 0, 0);
  }

  .list-item[data-type="1"] {
    transform: translate3d(-60px, 0, 0);
  }

  .lost_box {
    background: #fff;
    padding: 12px;
    overflow: hidden;

    .lost_box_l {
      float: left;
      font-size: 12px;
    }

    .lost_box_r {
      float: right;
      font-size: 10px;
      color: red;
    }
  }

  .list-box {
    background: #fff;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .shop_car_l {
      float: left;
      margin-left: 44px;

      i {
        font-size: 10px;
        color: red;
        font-style: normal;
        background: red;
        border-radius: 4px;
        color: #fff;
        padding: 3px 4px;
        box-sizing: border-box;
        transform: scale(0.8, 0.8);
        display: inline-block;
        vertical-align: 2px;
        font-weight: 500;
      }
    }

    .i {
      color: #333;
    }

    .shop_car_r {
      float: right;
      margin-right: 12px;
      color: red;
      font-size: 10px;
    }

    .list_all {
      overflow: hidden;
      position: relative;
      margin: 8px 0;

      .checkbox_no {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translate(-0%, -50%);

        span {
          font-size: 10px;
          background: rgba(0, 0, 0, 0.3);
          border-radius: 8px;
          padding: 1px 3px;
          box-sizing: border-box;
          color: #fff;
        }
      }

      .checkbox {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, .4);
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translate(-0%, -50%);
      }

      .checkbox.on {
        width: 24px;
        height: 24px;
        background: url("../../assets/images/choose.png") no-repeat;
        background-size: 100% 100%;
        border: none;
      }

      .list_all_img {
        width: 90px;
        height: 90px;
        margin-top: 5px;
        border-radius: 4px;
        overflow: hidden;
        float: left;
        margin-left: 50px;
        background: rgba(0, 0, 0, 0.4);

        img {
          width: 100%;
        }
      }

      .list_all_span {
        float: left;
        width: 200px;
        margin-left: 12px;
        text-align: left;

        .h1 {
          font-size: 12px;
          position: absolute;
          bottom: 0;
        }

        .h6 {
          font-size: 14px;
          position: absolute;
          top: 0;
          color: #999;
        }

        h1 {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 500;
          margin-bottom: 4px;

          span {
            font-size: 10px;
            color: red;
            background: red;
            border-radius: 4px;
            color: #fff;
            padding: 3px 4px;
            box-sizing: border-box;
            transform: scale(0.8, 0.8);
            display: inline-block;
            vertical-align: 2px;
          }
        }

        h2 {
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          span {
            background: rgba(0, 0, 0, .06);
            padding: 1px 4px;
            box-sizing: border-box;
            font-size: 12px;
            border-radius: 4px;
            color: #999;
          }
        }

        h3 {
          color: #999;
          margin-top: 7px;
          font-weight: 500;
          font-size: 13px;
          text-decoration: line-through;
        }

        h4 {
          color: red;

          h5 {
            display: inline-block;
            color: red;
            margin-top: 7px;
            font-size: 18px;
            font-weight: 500;
          }

          span {
            font-size: 14px;
          }
        }
      }

      .list_all_nub_btn {
        width: 90px;
        height: 30px;
        display: flex;
        position: absolute;
        line-height: 30px;
        right: 12px;
        bottom: 2px;

        div {
          flex: 1;
          border-radius: 3px;
          margin: 1px;
          background: rgba(0, 0, 0, .03);
          text-align: center;
          font-weight: 500;
        }
      }
    }
  }

  .list-item .delete {
    width: 60px;
    height: 110px;
    background: #ff4949;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 110px;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    right: -60px;
  }

  .shop_car_top {
    padding: 8px 0 8px 0;
    text-align: left;
    box-sizing: border-box;
    position: relative;

    img {
      width: 20px;
      margin-right: 7px;
      margin-left: 8px;
      vertical-align: -10px;
    }

    .checkbox {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, .4);
      margin-left: 12px;
      float: left;
    }

    .checkbox.on {
      width: 24px;
      height: 24px;
      background: url("../../assets/images/choose.png") no-repeat;
      background-size: 100% 100%;
      border: none;
    }

    span {
      font-weight: 500;
      vertical-align: -5px;
    }
  }

  .shop_car_bot {
    overflow: hidden;
    font-size: 10px;
    color: red;

    .shop_car_l {
      float: left;
      margin-left: 44px;

      i {
        font-size: 10px;
        color: red;
        font-style: normal;
        background: red;
        border-radius: 4px;
        color: #fff;
        padding: 3px 4px;
        box-sizing: border-box;
        transform: scale(0.8, 0.8);
        display: inline-block;
        vertical-align: 2px;
        font-weight: 500;
      }
    }

    .i {
      color: #333;
    }

    .shop_car_r {
      float: right;
      margin-right: 12px;
    }
  }

  .shop_car_bottom {
    width: 100%;
    line-height: 42px;
    text-align: left;
    font-size: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);

    .shop_car_yh {
      border: 1px solid red;
      margin-left: 44px;
      border-radius: 4px;
      padding: 1px 3px;
      color: red;
      margin-right: 8px;
    }

    .shop_car_nub {
      color: red;
    }

  }

}
</style>
